/* eslint-disable no-unused-vars */
import ServerRequest from '../helpers/ServerRequest';
import { ServerUrl } from '../constants/mainUrls';
import { ShowGlobalDialog } from '../store/GlobalDialogSlice';
import { store } from '../store/rootReducer';
import { getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS } from '../utils/general';

export const GetAllSources = async () => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/src/all/`);
  // console.log("/api/src/all/ ",data)
  return data;
};

export const SaveSourcesToDB = async (datas) => {
  console.log(
    getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
      ' Sources DBApi => SaveSourcesToDB reçues datas',
    datas
  );

  ServerRequest.post(`${ServerUrl}/api/src/apply/`, datas).then(
    (data) => {
      console.log(
        getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
          ' Sources DBApi => SaveSourcesToDB Resultat après exécution du ServerRequest.post  => ',
        data
      );
      store.dispatch(
        ShowGlobalDialog({
          message:
            getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
            ' Sources enregistrées avec succes',
          etat: true,
          typeMessage: 'success',
        })
      );
      return data;
    },
    (error) => {
      console.error(
        getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
          ' Sources DBApi => ApplySources ERREUR après exécution du ServerRequest.post  => ',
        error
      );
      store.dispatch(
        ShowGlobalDialog({
          message:
            getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
            " Erreor pendant l'enregistrement " +
            JSON.stringify(error),
          etat: true,
          typeMessage: 'error',
        })
      );
      throw error;
    }
  );
};
export const ApplyCommentaire = async (id, commentaire) => {
  let datas = {
    id: id,
    commentaire: commentaire,
  };
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/src/comm/`,
    datas
  );
  store.dispatch(
    ShowGlobalDialog({
      message:
        getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
        ' Commentaire enregistré avec succes',
      etat: true,
      typeMessage: 'success',
    })
  );
};
export const getSourcesForIdRenseigner = async (datas) => {
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/src/gets/`,
    datas
  );
  console.log(
    getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
      'DbApi, getSourcesForIdRenseigner  =>Datas for id =' +
      datas.idRenseigner +
      ' : =>',
    data
  );
  return data;
};
