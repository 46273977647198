import React, { useEffect, useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import MaterialTable, { MTableBodyRow } from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import { forwardRef } from 'react';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import ProtectContent from '../../helpers/ProtectContent';
import { UpdateRens } from '../../DbApi/Renseigner.DbApi';
import { GetAllSources } from '../../DbApi/sources.DBApi';
import DetailPanel from './DetailPanel';
import ShowComponent from '../../helpers/ShowComponent';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const TableIndicateurs = ({ SetDatas, columns, datas, options }) => {
  const [open, setOpen] = useState(false);
  const [sources, setSources] = useState([]);
  //const navigate = useNavigate();
  /*   const actions=[
    {
      icon: () => <SourceIcon/>,
      tooltip: 'Sources de Vérification',
      onClick: (event, rowData) => OnSourceRow(rowData)
      },
    ]  */
  /*   const OnEditRow = (rowData) => {};
  const OnSourceRow = (rowData) => {
    console.log('OnSourceRow rowData', rowData);

    navigate('/setsrc', { state: rowData });
  }; */
  const defaultMaterialTheme = createTheme();

  useEffect(() => {
    async function fetchData() {
      setSources(await GetAllSources());
    }
    fetchData();
  }, []);
  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          options={options}
          /* actions={actions} */
          title=""
          data={datas}
          editable={{
            /*  onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                SetDatas([...datas, newData]);
                
                resolve();
              }, 1000)
            }), */
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                UpdateRens(newData);
                const dataUpdate = [...datas];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                SetDatas([...dataUpdate]);
                resolve();
              }),
            /* onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...datas];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                SetDatas([...dataDelete]);
                
                resolve()
              }, 1000)
            }), */
          }}
          components={{
            Row: (props) => (
              <MTableBodyRow
                {...props}
                onDoubleClick={(e) => {
                  console.log('props.actions', props.actions);
                  props.actions[0]().onClick(e, props.data);
                }}
                /*   onRowClick={e => {
                console.log("props.actions",props.actions);
                props.actions[0]().onClick(e,props.data);
              }} */
              />
            ),
          }}
          detailPanel={(rowData) => {
            console.log('Dans le tableau des indicateurs,rowData= ', rowData);
            return (
              <DetailPanel
                sources={sources}
                idRenseigner={rowData.idRenseigner}
              />
            );
          }}
        />
      </ThemeProvider>
      <ShowComponent open={open} setOpen={setOpen}></ShowComponent>
    </div>
  );
};

export default ProtectContent(TableIndicateurs);
