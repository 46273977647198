import React, { useState, useEffect } from 'react';
import './DetailPanel.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS } from '../../utils/general';
import Select from 'react-select';
import Button from '@mui/material/Button';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import {
  SaveSourcesToDB,
  ApplyCommentaire,
  getSourcesForIdRenseigner,
} from '../../DbApi/sources.DBApi';
import { GetRens } from '../../DbApi/Renseigner.DbApi';

const DetailPanel = ({ idRenseigner, sources }) => {
  const [selectedOption, SetSelectedOption] = useState([]);
  const [firstOption, SetfirstOption] = useState([]);
  const [commentaire, setCommentaire] = useState('');
  const [rowData, setRowData] = useState({});

  const handleChangeCommentaire = (event) => {
    setCommentaire(event.target.value);
  };

  const handleChange = async (selectedOption) => {
    SetSelectedOption(selectedOption);
    console.log('**************    selectedOption', selectedOption);
  };

  useEffect(() => {
    async function fetchData() {
      GetRens({ idRenseigner: idRenseigner }).then((detail) => {
        console.log('detail = ', detail);
        setRowData(detail);
        setCommentaire(detail.Commentaire);
      });

      console.log(
        getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
          ' UseEffect is called with this data= ',
        rowData
      );

      const resultat = await getSourcesForIdRenseigner({
        idRenseigner: idRenseigner,
      });
      var tableau2 = [];
      // eslint-disable-next-line array-callback-return
      resultat.map((item) => {
        let UneSource = sources.find((sc) => {
          return sc.value === item.Source_id;
        });
        tableau2.push(UneSource);
      });
      SetSelectedOption(tableau2);
      SetfirstOption(tableau2);
      //USStates.find(({ value }) => value === props.state)
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRenseigner, sources]);

  const SaveOnlySources = async () => {
    let reset = false;
    let TheTab = [];

    // eslint-disable-next-line array-callback-return
    selectedOption.map((item) => {
      let UnObj = {
        Renseigner_id: rowData.idRenseigner,
        Source_id: item.value,
      };
      TheTab.push(UnObj);
    });
    console.log('******** Liste des sources sélectionnées', TheTab);
    if (selectedOption.length === 0 && firstOption.length > 0) reset = true;
    let donnees = {
      reset: reset,
      TheTab: TheTab,
      idRenseigner: rowData.idRenseigner,
    };
    SaveSourcesToDB(donnees);
  };

  const AppliquerSources = async () => {
    // if (selectedOption.length > 0 || firstOption.length > 0)
    await SaveOnlySources();
    await ApplyCommentaire(rowData.idRenseigner, commentaire);
  };

  //idRenseigner=2522,2549
  return (
    <div className="d-flex flex-column justify-content-center align-items-center detailmain">
      <div className="col-8 mt-2 mb-1">
        Résultat : {rowData.CodeResultat} -- {rowData.LibResultat}
      </div>
      <div className="col-8 mt-3 mb-1 textColorBrown">
        Indicateur : {rowData.Indicateurs_id} -- {rowData.LibIndicateur}
      </div>

      <div className="col-8 mt-3 mb-3">
        <strong>
          Veuillez choisir une ou plusieurs sources de vérification
        </strong>
      </div>
      <div className="col-8">
        <Select
          value={selectedOption}
          isMulti={true}
          name="Sources"
          options={sources}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleChange}
          // defaultValue={[colourOptions[2], colourOptions[3]]}
        />
      </div>
      <div className="col-8 mt-3 mb-3">
        <label>
          <strong>Commentaire/Observation (facultatif)</strong>
        </label>
        <textarea
          value={commentaire}
          onChange={handleChangeCommentaire}
          rows={10}
          cols={50}
        />
      </div>
      <div className="d-flex flex-row justify-content-center mt-3">
        <Button variant="contained" color="success" onClick={AppliquerSources}>
          {' '}
          <DoneOutlineIcon color="action" /> Enregistrer Modifications
        </Button>
      </div>
    </div>
  );
};

export default DetailPanel;
