export const getNicePeriod = (report) => {
  if (report.type.frequency === 'YEAR') return `Année ${report.year}`;
  if (report.type.frequency === 'QUARTER')
    return `${report.orderNo === 1 ? '1er' : `${report.orderNo}e`} Trimestre ${
      report.year
    }`;
  if (report.type.frequency === 'SEMESTER')
    return `${report.orderNo === 1 ? '1er' : `${report.orderNo}e`} Semestre ${
      report.year
    }`;
};

export const getStatusColor = (status) => {
  const names = {
    WAITING: 'orange',
    ACCEPTED: 'green',
    REJECTED: 'red',
    CANCELED: 'red',
  };
  return names[status];
};

export const getStatusIntent = (status) => {
  const names = {
    WAITING: 'warning',
    ACCEPTED: 'success',
    REJECTED: 'danger',
    CANCELED: 'danger',
  };
  return names[status];
};

export const getStatusNiceName = (status) => {
  const names = {
    WAITING: 'En Attente',
    ACCEPTED: 'Accepté',
    REJECTED: 'Rejeté',
    CANCELED: 'Annulé',
  };
  return names[status];
};

export function getCurrentUserId() {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('currentUserId') || null;
  }
  return null;
}

export const removeExtension = (fileName) =>
  fileName.split('.').slice(0, -1).join('.');

export const getExtension = (fileName) => fileName.split('.').pop();

const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];
export const frDate = (iso, noTime) => {
  if (!iso) return null;
  const d = new Date(iso);
  const part1 = `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`;
  const part2 = noTime ? '' : ` ${d.getHours()}:${d.getMinutes()}`;
  return `${part1}${part2}`;
};

export function getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() {
  function toString(number, padLength) {
    return number.toString().padStart(padLength, '0');
  }

  let date = new Date();

  let dateTimeNow =
    toString(date.getFullYear(), 4) +
    '_' +
    toString(date.getMonth() + 1, 2) +
    '_' +
    toString(date.getDate(), 2) +
    '__' +
    toString(date.getHours(), 2) +
    '_' +
    toString(date.getMinutes(), 2) +
    '_' +
    toString(date.getSeconds(), 2) +
    '_' +
    toString(date.getMilliseconds(), 3);
  return dateTimeNow;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}
