import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import MainApp from './MainApp';
import { store } from './store/rootReducer';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <SnackbarProvider maxSnack={5}>
      <BrowserRouter>
        <MainApp />
      </BrowserRouter>
    </SnackbarProvider>
  </Provider>
);
