import React, { useEffect, useState } from 'react'
//import { MenuItem,Select as MuiSelect ,FormControl,InputLabel } from '@mui/material';
import { Button } from '@mui/material';
import { GetAllPta } from '../../DbApi/Pta.DbApi';
import Select from 'react-select';
import TableIndicateurs from './TableIndicateurs';
import { GetAllRens,GenererRenseigner } from '../../DbApi/Renseigner.DbApi';
import ProtectContent from '../../helpers/ProtectContent';
import { Head } from 'react-static';
import './Evalpta.css';

const MainComponent = () => {
   const [LesAnnees,SetLesAnnees]=useState([]) 
   const [annePta,SetAnnePta]=useState('')
   const [selectedOption, setSelectedOption] = useState(null);
   const [RowsDatas, setRowsDatas] = useState([]);
    
   const handleSelectChange=(event)=>{
        console.log("event.target.value :",event.target.value)
        SetAnnePta(event.target.value)
    }

   const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    SetAnnePta(selectedOption.value)
    //console.log("selectedOption :",selectedOption)
   }

  useEffect(() => {
    async function fetchData() {
        const lesYears=await GetAllPta()
        let currentUser=JSON.parse(sessionStorage.getItem('currentUser'))
        let param={ Entiter:currentUser.EntiterId,AnneePta:annePta }
        setRowsDatas(await GetAllRens(param))
        //console.log(" UseEffect Calll, Annee=",annePta,lesYears,currentUser)
        SetLesAnnees(lesYears)
    }
    fetchData();
  }, [annePta])

  const columns=[
        {title: "Code",field: "Indicateurs_id",  editable:'never'},
        {title: "idRenseigner", field: "idRenseigner", editable:'never', hidden: true},
        {title: "Indicateur", field: "Indicateur", editable:'never', hidden: true},
        {title: "Indicateur", field: "LibIndicateur", editable:'never'},
        {title: "Valeur", field: "Valeur",type: 'numeric'},
        {title: "Entiter_id", field: "Entiter_id", editable:'never', hidden: true},
        {title: "PTA_id", field: "PTA_id", editable:'never', hidden: true},
  ]
  const [selectedRow, setSelectedRow] = useState(null);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    })
  }
  const Encours = LesAnnees.find( item => item.enCours === 1);
  
  const options={
    exportButton: true,
    pageSize: 65,
    pageSizeOptions: [65, 100, 150],
    actionsColumnIndex: -1,
    rowStyle: rowData => ({
      backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
    }),
    headerStyle: {
        position: "sticky",
        top: "0",
        backgroundColor:'brown',
        fontWeight: 'bold',
        color: 'white',
      },
    maxBodyHeight: "500px",
    
    
  }
  
 
  return (
  <>
    <Head>
      <title>Caritas Bénin| Plateforme de Suivi-Evaluation de Redevabilité et d'apprentissage | Evaluation Plan Stratégique</title>
    </Head>
    <div className='bgc mt-3'><strong>Saisie des Indicateurs du PTA {annePta}</strong></div>
    <div className='row'>
    <div className=' col-md-2 col-xs-2' > Veuillez choisir une Année
    <Select
        defaultValue={selectedOption}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        onChange={handleChange}
        options={LesAnnees}
        theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
            ...theme.colors,
              text: '#3599B8',
              font:'#3599B8',
              primary25: '#3599B8',
              primary: '#3599B8',
              neutral80: 'black',
              color: 'black',
            },
          })}
          styles={customStyles}
        
        />

    </div> 
    
   
    <div className='col-md-10'>
        <TableIndicateurs SetDatas={setRowsDatas} datas={RowsDatas} columns={columns} options={options} />
    </div>
   {/* <div className='col-md-2'>
        <Button onClick={GenererRenseigner}>Générer</Button>
    </div>*/}
    </div>{/* Fin row 1*/}
  </>
  )
}

export default ProtectContent(MainComponent)