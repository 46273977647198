import ServerRequest from '../helpers/ServerRequest';
import { ServerUrl } from '../constants/mainUrls';
export const UserExists= async (email ) => {
       const Param={'email':email}
        const rep= await ServerRequest.post(`${ServerUrl}/api/users/exist/`,Param)
        //console.log("/api/users/exist/ : ",rep.data[0].nbre)
      return rep.data[0].nbre;
    };

export const GetUserByEmail = async(email)=> {
  const Param={'email':email}
  const rep= await ServerRequest.post(`${ServerUrl}/api/users/getuserbymail/`,Param)
  console.log("/api/users/getuserbymail/ : ",rep.data[0])
  return rep.data[0];
}
 
export const SeConnecter = async(data)=> {
  //await ServerRequest.post(`${ServerUrl}/api/users/upwd/`,data)
  const rep= await ServerRequest.post(`${ServerUrl}/api/users/connect/`,data)
  console.log("/api/users/connect/ : ",rep.data)
  return rep.data;
}
export const insertUser = async(data)=> {
  const rep= await ServerRequest.post(`${ServerUrl}/api/users/insert/`,data)
  console.log("/api/users/insert/ : ",rep.data)
  return rep.data;
}
export const UpdatePassWord = async(data)=> {
  const rep= await ServerRequest.post(`${ServerUrl}/api/users/upwd/`,data)
  console.log("/api/users/upwd/ : ",rep.data)
  return rep;
}
  

