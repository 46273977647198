import { Routes,Route } from 'react-router-dom';
import Home from './containers/Home'
import LogInForm from './containers/LogInForm';
import Logout from './containers/Logout';
import AnnuelReportForm from './containers/AnnuelReport/AnnuelReportForm';
import ShowUploadRepportsForm from './containers/AnnuelReport/ShowUploadRepportsForm';
//import Evalpta from './containers/EvalPtas/Evalpta';
import Evalpta from './containers/Evalpta/Evalpta';
import SetSourcesVerifications from './containers/Evalpta/SetSourcesVerifications';
import ShowCreateUser from './containers/CreateUser/ShowCreateUser';
import { OnlineStatusProvider } from './helpers/useOnlineStatus';
import Notifier from './store/Alertes/Notifier';
import AlertMessage from './containers/AlertMessage';
import ShowChangePassWord from './containers/ChangePassWord/ShowChangePassWord';
import AdminPta from './containers/AdminPta/AdminPta';
function MainApp() {
  Notifier();
  return (
    <div >
      <OnlineStatusProvider>
      <Routes >
        <Route path='/' element={<Home/>} />
        <Route path='/login' element={<LogInForm/>} />
        <Route path='/chpssw' element={<ShowChangePassWord/>} />
        <Route path='/bord' element={<Evalpta/>} />
        <Route path='/logout' element={<Logout/>} />
        <Route path='/rpword' element={<ShowUploadRepportsForm/>} />
        <Route path='/evalps' element={<Evalpta/>} />
        <Route path='/setsrc' element={<SetSourcesVerifications/>} />
        <Route path='/crus' element={<ShowCreateUser/>} />
        <Route path='/view' element={<AdminPta/>} />
      </Routes>
      </OnlineStatusProvider>  
      <AlertMessage/>
    </div>
  );
}  

export default MainApp;
