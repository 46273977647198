import React, { useRef, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
} from '@mui/material';
import { UserExists } from '../DbApi/Users.DbApi.js';
import { UserRoleSeConnecter } from '../DbApi/UserRole.DbApi.js';
import { useNavigate } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoginIcon from '@mui/icons-material/Login';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Head } from 'react-static';
import caritasLogo from '../assets/images/caritas-logo.jpg';
import pageBg from '../assets/images/pexels-photo-273238.jpeg';
import './LogInForm.scss';

const LogInForm = () => {
  const naviguer = useNavigate();
  const UserInfo = useRef({});
  const TheToken = localStorage.getItem('se');
  const [showPassword, setShowPassword] = useState(false);
  const [MainMessageError, setMainMessageError] = useState(null);
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const [currentUser, SetcurrentUser] = useState(
    sessionStorage.getItem('currentUser')
  );

  useEffect(() => {
    console.log('currentUser ', JSON.parse(currentUser));
    if (JSON.parse(currentUser))
      naviguer('/bord', { state: JSON.parse(currentUser) });
  }, [currentUser]);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({ mode: 'onTouched' });
  const onSubmit = async (data) => {
    console.log('Le formulaire est validé. ', data);
    //  const re= await SeConnecter(data)
    const re = await UserRoleSeConnecter(data);
    if (!re.valid) {
      setMainMessageError('Mot de passe erroné');
    } else {
      setMainMessageError(null);
      sessionStorage.setItem('currentUser', JSON.stringify(re.user));
      SetcurrentUser(JSON.stringify(re.user));
      naviguer('/bord', { state: re.user });
    }
    console.log(re);
  };

  return (
    <div
      className="min-vh-100 d-flex flex-column justify-content-center align-items-center"
      style={{ backgroundImage: `url(${pageBg})` }}
    >
      <Head>
        <title>
          Connexion | Plateforme de Suivi-Evaluation de Redevabilité et
          d'apprentissage| Caritas Bénin
        </title>
        <link
          href="https://fonts.googleapis.com/css?family=Cabin:400,700"
          rel="stylesheet"
        ></link>
      </Head>
      <div className="fs-4 text-center texteTitre">
        Plateforme de Suivi-Evaluation de Redevabilité et d'apprentissage
      </div>

      <div
        className="text-center "
        style={{ backgroundColor: 'rgba(0,0,0,.6)' }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={caritasLogo}
            alt="Logo de Caritas Bénin "
            width={350}
            className="fluid"
          />
          <h3 className="border-b text-center mb-3 pb-3 font-bold texteInvitation">
            Veuillez vous connecter
          </h3>

          {!TheToken && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                ></Grid>

                {MainMessageError && (
                  <h3 className="text-danger border-b text-center mb-3 pb-3 font-bold">
                    {MainMessageError}
                  </h3>
                )}

                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={''}
                      rules={{
                        required: 'Ce champ doit avoir une valeur',
                        validate: async (value) => {
                          let resultat = await UserExists(value);
                          return resultat === 1;
                        },
                      }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { isTouched, isDirty, error },
                        formState,
                      }) => (
                        <TextField
                          label={
                            <span style={{ color: '#0D2801' }}>
                              {' '}
                              <MailOutlineIcon /> Email{' '}
                            </span>
                          }
                          onChange={onChange}
                          value={value}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </Grid>

                  <Grid>
                    {errors.email && (
                      <small className="text-danger">
                        {errors.email.message}
                      </small>
                    )}
                    {errors.email && errors.email.type === 'validate' && (
                      <div className="text-danger">Ce Email n'existe pas</div>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue={''}
                      rules={{
                        required: 'Ce champ doit avoir une valeur',
                        validate: (value) => {
                          if (UserInfo.current.exist) {
                            return UserInfo.current.item[0].Userpwdl === value;
                          }
                        },
                      }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { isTouched, isDirty, error },
                        formState,
                      }) => (
                        <TextField
                          label={
                            <span style={{ color: '#0D2801' }}>
                              <VpnKeyIcon />
                              Mot de passe{' '}
                            </span>
                          }
                          onChange={onChange}
                          value={value}
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleClick} edge="end">
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid>
                    {errors.password && (
                      <small className="text-danger">
                        {errors.password.message}
                      </small>
                    )}
                    {errors.password && errors.password.type === 'validate' && (
                      <div className="text-danger">Mot de passe erroné</div>
                    )}
                  </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center">
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      startIcon={<CancelOutlinedIcon />}
                      onClick={() => {
                        reset({ Email: '', PassWord: '' });
                        naviguer('/');
                      }}
                    >
                      {' '}
                      Abandonner
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      type="submit"
                      startIcon={<LoginIcon />}
                    >
                      {' '}
                      Connecter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogInForm;
