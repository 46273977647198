import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
//import { errorSlice } from './error/errorSlice';
import { globalDialogReducer } from './GlobalDialogSlice';

export const rootReducer = combineReducers({
 // error: errorSlice.reducer,
  globalDialogStatus:globalDialogReducer,
  //other reducers here
})
export const store = configureStore({
  reducer: rootReducer,
  /* devTools: process.env.NODE_ENV !== 'production', */
  devTools:true,
})