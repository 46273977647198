import React, { useState } from 'react';
import { store } from '../../store/rootReducer';
import { useNavigate } from 'react-router-dom';
import { ShowGlobalDialog } from '../../store/GlobalDialogSlice';
import './AdminPta.css';
import TemplatePages from '../Dashboard/TemplatePages';
import MainComponent from './MainComponent';

const AdminPta = () => {
  const naviguer = useNavigate();
  let tempo = sessionStorage.getItem('currentUser');
  const currentUser = JSON.parse(tempo);
  console.log('AdminPta =>currentUser=', currentUser);
  if (!currentUser) {
    let message = 'Veuillez vous connecter';
    store.dispatch(
      ShowGlobalDialog({ message: message, etat: true, typeMessage: 'error' })
    );
    naviguer('/login');
  } else {
    let index = currentUser.roles.findIndex(
      (item) => item.LibRole === 'Administrateur'
    );
    if (index === -1) {
      let message =
        "Vous n'avez pas les droits d'accès à cette fonctionnalité de la plateforme\n\rVous êtes automatiquement redirigés vers l'écran de saisie des indicateurs";
      store.dispatch(
        ShowGlobalDialog({ message: message, etat: true, typeMessage: 'error' })
      );
      naviguer('/bord');
    }
  }

  return <TemplatePages Fils={<MainComponent />} />;
};
export default AdminPta;
