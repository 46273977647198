import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { ShowGlobalDialog } from '../store/GlobalDialogSlice';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMessage=()  =>{
 const {message,globalDialogVisible,typeMessage}= useSelector((state) => state.globalDialogStatus);   
/*  const message= useSelector((state) => state.globalDialogStatus?.message);
 const globalDialogVisible= useSelector((state) => state.globalDialogStatus?.globalDialogVisible);
 const typeMessage= useSelector((state) => state.globalDialogStatus?.typeMessage); */
const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ShowGlobalDialog({message:'',etat:false,typeMessage:""}))
  };
 if (typeMessage) var TypeDialogue=typeMessage 
 else var TypeDialogue="error"

 // valeurs possibles de everity="error", "warning" ,  "info" , "success"
 var vertical='bottom',horizontal= 'right';
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={globalDialogVisible} autoHideDuration={5000} 
      anchorOrigin={{vertical,horizontal}}
      onClose={handleClose}>
        <Alert onClose={handleClose} severity={TypeDialogue} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
         
      
    </Stack>
  );
}
export default AlertMessage;