import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
} from '@mui/material';
import { UserExists, insertUser } from '../../DbApi/Users.DbApi';
import { useNavigate } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoginIcon from '@mui/icons-material/Login';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import HomeIcon from '@mui/icons-material/Home';
import { Select as MuiSelect } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { getEntiterForSelectBox } from '../../DbApi/entiter.DbApi';
import './CreateUser.css';

const CreateUser = () => {
  const naviguer = useNavigate();
  const [message, setMessage] = useState('');
  const [resultat, setResultat] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedEntiter, setSelectedEntiter] = useState('');
  const [entiters, setEntiters] = useState([]);
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({ mode: 'onTouched' });

  const onSubmit = async (data) => {
    console.log('Le formulaire est validé. ', data);
    //const date = new Date().toISOString().slice(0, 19).replace("T", " ");
    data.id = null;
    let retour = await insertUser(data);
    console.log('Insertion new user résultat ', retour);
    if (retour === 'OK') {
      setResultat(true);
      setMessage('Le compte a été créé avec success');
    } else {
      setResultat(false);
      setMessage("Une erreur s'est produite, veuillez recommencer");
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };
  // eslint-disable-next-line no-unused-vars
  const handleChangeEntiter = (e) => {
    console.log('selectedOption', e.target.value);
    setSelectedEntiter(e.target.value);
  };

  useEffect(() => {
    async function fetchData() {
      var Lesentiters = await getEntiterForSelectBox();
      setEntiters(Lesentiters);
    }
    fetchData();
  }, []);

  return (
    <div className="container d-flex flex-column justify-content-center align-items-center">
      <small className={resultat ? 'text-success' : 'text-danger'}>
        {message}
      </small>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div>
            <h3 className="border-b text-center mb-3 pb-3 font-bold">
              Enregistrement
            </h3>
          </div>
          <div className="col-12 mt-2 mb-3 d-flex flex-column ">
            <strong>Veuillez choisir un diocèse</strong>
            <Controller
              name={'EntiterId'}
              defaultValue=""
              control={control}
              rules={{
                required: 'Ce champ doit avoir une valeur',
              }}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <FormControl>
                    <MuiSelect
                      label={'Diocèses'}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      {entiters.map((entite) => (
                        <MenuItem key={entite.value} value={entite.value}>
                          {entite.label}
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                );
              }}
            />
            <div>
              {errors.EntiterId && (
                <small className="text-danger">
                  {errors.EntiterId.message}
                </small>
              )}
            </div>
          </div>

          <Grid
            container
            item
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div className="margebas">
              <Controller
                name="firstName"
                control={control}
                defaultValue={''}
                rules={{
                  required: 'Ce champ doit avoir une valeur',
                }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                  formState,
                }) => (
                  <TextField
                    label={
                      <span style={{ color: '#0D2801' }}>
                        {' '}
                        <PersonIcon /> Prénoms *
                      </span>
                    }
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            <Grid>
              {errors.firstName && (
                <small className="text-danger">
                  {errors.firstName.message}
                </small>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div className="margebas">
              <Controller
                name="lastName"
                control={control}
                defaultValue={''}
                rules={{
                  required: 'Ce champ doit avoir une valeur',
                }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                  formState,
                }) => (
                  <TextField
                    label={
                      <span style={{ color: '#0D2801' }}>
                        {' '}
                        <PersonIcon /> Nom *
                      </span>
                    }
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            <Grid>
              {errors.lastName && (
                <small className="text-danger">{errors.lastName.message}</small>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div className="margebas">
              <Controller
                name="email"
                control={control}
                defaultValue={''}
                rules={{
                  required: 'Ce champ doit avoir une valeur',
                  validate: async (value) => {
                    let resultat = await UserExists(value);
                    console.log('Le mail existe déjà ? ', resultat);
                    return resultat === 0;
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                  formState,
                }) => (
                  <TextField
                    label={
                      <span style={{ color: '#0D2801' }}>
                        {' '}
                        <MailOutlineIcon /> Email *{' '}
                      </span>
                    }
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            <Grid>
              {errors.email && (
                <small className="text-danger">{errors.email.message}</small>
              )}
              {errors.email && errors.email.type === 'validate' && (
                <div className="text-danger">Ce Email existe déjà</div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div className="margebas">
              <Controller
                name="password"
                control={control}
                defaultValue={''}
                rules={{
                  required: 'Ce champ doit avoir une valeur',
                  /* validate:(value)=>{
                            if (UserInfo.current.exist )
                            {
                                return (UserInfo.current.item[0].Userpwdl===value)
                            }
                        } */
                }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                  formState,
                }) => (
                  <TextField
                    label={
                      <span style={{ color: '#0D2801' }}>
                        <VpnKeyIcon />
                        Mot de passe *
                      </span>
                    }
                    onChange={onChange}
                    value={value}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClick} edge="end">
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <Grid>
              {errors.password && (
                <small className="text-danger">{errors.password.message}</small>
              )}
              {errors.password && errors.password.type === 'validate' && (
                <div className="text-danger">Mot de passe erroné</div>
              )}
            </Grid>
          </Grid>

          {!resultat && (
            <Grid
              container
              direction="row"
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <Button
                size="small"
                variant="contained"
                color="error"
                startIcon={<CancelOutlinedIcon />}
                onClick={() => {
                  reset({ Email: '', PassWord: '' });
                  naviguer('/');
                }}
              >
                {' '}
                Abandonner
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<LoginIcon />}
              >
                {' '}
                Créer le compte
              </Button>
            </Grid>
          )}
          {resultat && (
            <Grid
              container
              direction="row"
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <Button
                size="small"
                variant="contained"
                color="success"
                startIcon={<HomeIcon />}
                onClick={() => {
                  naviguer('/bord');
                }}
              >
                {' '}
                Page d'accueil
              </Button>
            </Grid>
          )}
        </div>
      </form>
    </div>
  );
};
export default CreateUser;
