import NewSidebar from "../../components/NewSideBar/NewSidebar";
import Topbar from "../../components/TopBar/Topbar";
import ProtectContent from "../../helpers/ProtectContent";
import './TableauxDeBord.scss';
const TemplatePages = ({Fils})=>{
  /*   const location = useLocation();
     user=location.state
    console.log("user = ",location.user) */
    let currentUser=sessionStorage.getItem('currentUser')
    const user=JSON.parse(currentUser)
return (
    <>
       
        <div className="row">
        <Topbar user={user}/>
        </div>
        <div className="board">
            <NewSidebar />
            <div className="boardContainer toutHauteur">{Fils}</div>
        </div>
    </>
)
}

export default ProtectContent(TemplatePages)