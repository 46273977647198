import React from 'react'
import caritasLogo from '../../assets/images/caritas-logo.jpg'
import {Link} from "react-router-dom";
import OnLineBar from '../../containers/Dashboard/OnLineBar';
import './Topbar.scss'
const HEADER_HEIGHT = 60;

const Topbar=({user})=>{
    
    return (
        <div className='topbar'>
            
            <Link to="/evalps" style={{ textDecoration: "none" }}>
                <img className="logo" src={caritasLogo} style={{ height: HEADER_HEIGHT - 4 }} />
            </Link>
            <div className='text'>
                CARITAS Bénin |S.E.R.A 
            </div>
            <div >
                 <OnLineBar/>
            </div>
            <div>
                {user.email} <br/>{user.NomEntiter} 
            </div>
        </div>
    )
}
export default  Topbar