import axios from "axios";
import { store } from "../store/rootReducer";
import { ShowGlobalDialog } from "../store/GlobalDialogSlice";

const ServerRequest = axios.create({})
 ServerRequest.interceptors.response.use(
        (response) => {
           return response
          }, 
          (err) => {
            const response = err?.response
            const config = err?.config
            const data = response?.data
            const status=err?.status;
            //ERR_NETWORK
            const message =`ServerRequest.interceptors  => code erreur = ${err.code} =>status erreur= ${err.response?.status}` 
            console.log(message)
            store.dispatch(ShowGlobalDialog({message:message,etat:true,typeMessage:"error"}))
            return Promise.reject(err)
          }
        )
  export default ServerRequest