import React from 'react';
import useOnlineStatus from "../../helpers/useOnlineStatus";
import './OnLineBar.css'

const OnLineBar = () => {
    const isOnline = useOnlineStatus();
   
  return (
  <>
     <div className = { isOnline ? "onLine": "OffLine"}>
       {isOnline ? "Connecté au serveur" : "déconnecté du serveur"}
    </div>
    
  </>
  )
}

export default OnLineBar