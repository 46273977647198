import ServerRequest from '../helpers/ServerRequest';
import { ServerUrl } from '../constants/mainUrls';

export const GetAllRens = async (param) => {
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/rens/allrens/`,
    param
  );
  return data;
};
export const GetAllRensV2 = async (param) => {
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/rens/allrensV2/`,
    param
  );
  return data;
};
export const GenererRenseigner = async (param) => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/generer/`);
  return data;
};
export const UpdateRens = async (param) => {
  //console.log("UpdateRens ",param)
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/up/`, param);
  return data;
};
export const GetRens = (param) => {
  console.log('GetRens param ', param);
  var leresultat = ServerRequest.post(`${ServerUrl}/api/rens/id/`, param).then(
    (result) => {
      const { data } = result;
      console.log('GetRens Résulat appel API ', data[0]);
      return data[0];
    }
  );
  return leresultat;
};
