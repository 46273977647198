import SidebarItem from "./SidebarItem"
import items from "./data/sidebar.json"
import './sidebar.css'
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faBars,faXmark);

export default function NewSidebar(){
    const [sideBar,setSideBar]=useState(false)
    const showSideBar=()=> {
      setSideBar(!sideBar)}
    return (
       <>
       <div className="navBar">
       <button
          type="button"
          className="btn btn-sm btn-secondary m-2 ml-auto"
          name={"butonClose"}
          onClick={()=>showSideBar()}
        >
          <FontAwesomeIcon name={'Close'} icon={faBars} />
        </button>
        </div>

       <nav className={sideBar ? "nav-Menu-active":"nav-Menu"}>
            <ul className="nav-menu-Items">
              <div className="sidebar">
                { items.map((item, index) => <SidebarItem key={index} item={item} />) }
              </div>
            </ul>
       </nav>
       </>
    )
}