/* const IsLocalHost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
    )
) */ /* export const ServerUrl=IsLocalHost 
? "http://localhost:3555"
: "http://51.79.73.144:3555" */

/* export const ClientUrl=IsLocalHost 
? "http://localhost:3000"
: "http://sera.caritasbenin.org" */

//export const ServerUrl = 'http://localhost:3555';
//export const ServerUrl = 'http://51.79.73.144:3555';
export const ServerUrl = 'https://serav2.caritasbenin.org';
export const ClientUrl = 'http://sera.caritasbenin.org';
